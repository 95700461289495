export enum ValidColumnsMarketCap {
  currentPrice = 'current_price',
  priceChangePercentage1h = 'price_change_percentage_1h_in_currency',
  priceChangePercentage24h = 'price_change_percentage_24h_in_currency',
  priceChangePercentage7d = 'price_change_percentage_7d_in_currency',
  totalVolume = 'total_volume',
  marketCap = 'market_cap',
}

const validColumns = Object.values(ValidColumnsMarketCap);
const isValidMarketCapColumn = (column: string | null): column is ValidColumnsMarketCap =>
  !column ? false : validColumns.includes(column as ValidColumnsMarketCap);

export const fromQueryToMarketCap = (q: string | null) =>
  isValidMarketCapColumn(q) ? q : ValidColumnsMarketCap.marketCap;
