import clsx from 'clsx';
import { TableCell } from 'moralis-ui';
import { CoinLogo } from '@/components/common/CoinLogo';
import { GetLatestMarketDataResponseDto } from '@/services/models/GetLatestMarketDataResponseDto';
import { formatMetric, formatTokenPrice } from '@/utils/fmt';
import styles from './MarketCapPageTableRowUI.module.scss';

export const MarketCapPageTableRowUI = ({
  token,
  rowNumber,
}: {
  rowNumber: number;
  token: GetLatestMarketDataResponseDto;
}) => {
  return (
    <tr>
      <Col className={styles.fieldToken}>
        <a
          className={styles.tokenInfo}
          href={`https://www.coingecko.com/en/coins/${token.id}`}
          key={`${token.id || 'default'}-${token.address}-${token.symbol}`}
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noreferrer"
        >
          <span className={clsx('caption-14-regular', styles.tokenInfoRowNumber)}>{rowNumber}</span>
          <span className={styles.tokenInfoBox}>
            <span className={styles.tokenInfoBoxLogo}>
              <CoinLogo name={token.name || token.symbol || 'N/A'} size="small" src={token.image} />
            </span>
            <span className={styles.tokenInfoBoxTitle}>
              <span
                className={clsx('body-16-medium', styles.tokenInfoBoxTitleNameDesktop, styles.tokenInfoBoxTitleName)}
                title={token.name}
              >
                {token.name}
              </span>
              <span
                className={clsx(styles.tokenInfoBoxTitleName, styles.tokenInfoBoxTitleNameMobile, 'body-16-medium')}
                title={token.symbol}
              >
                {token.symbol}
              </span>
              <span className={clsx('caption-12-regular', styles.tokenInfoBoxTitleSymbol)}>{token.symbol}</span>
            </span>
          </span>
        </a>
      </Col>
      <Col>
        <BoxedPrice value={token.current_price} />
      </Col>
      <ColPercentage value={token.price_change_percentage_1h_in_currency} />
      <ColPercentage value={token.price_change_percentage_24h_in_currency} />
      <ColPercentage value={token.price_change_percentage_7d_in_currency} />
      <Col>
        <BoxedPrice value={token.total_volume} />
      </Col>
      <Col>
        <BoxedPrice value={token.market_cap} />
        {token.market_cap_change_percentage_24h !== 0 && (
          <span
            className={clsx('caption-12-medium', styles.tokenInfoBoxValue, {
              [styles.positive]: token.market_cap_change_percentage_24h > 0,
              [styles.negative]: token.market_cap_change_percentage_24h <= 0,
            })}
          >
            {formatMetric(token.market_cap_change_percentage_24h, 'percent', true).value}
          </span>
        )}
      </Col>
    </tr>
  );
};
const Col = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
  <TableCell align="left" className={clsx(styles.fieldTable, className)}>
    {children}
  </TableCell>
);
const Boxed = ({ children, className }: React.PropsWithChildren<{ className?: string | Record<string, boolean> }>) => (
  <span className={clsx('body-16-medium', styles.tokenInfoBoxValue, className)}>{children}</span>
);
const BoxedPrice = ({ value }: { value: number | null }) => (
  <Boxed>{formatTokenPrice(value !== null ? value : undefined)}</Boxed>
);

const ColPercentage = ({ value }: { value: number }) => {
  if (!value) {
    return <Col />;
  }
  const formatted = formatMetric(value, 'percent', true).value;
  return (
    <Col>
      <Boxed
        className={{
          [styles.positive]: value > 0,
          [styles.negative]: value <= 0,
        }}
      >
        {formatted}
      </Boxed>
    </Col>
  );
};
