import { useRouter } from 'next/router';
import { ButtonPrimary, Empty } from 'moralis-ui';
import { ERoutePath } from '@/utils/ERoutePath';
import { faClockRotateLeft, faCompass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MarketCapPageUIEmpty = () => {
  const router = useRouter();
  return (
    <Empty
      cta={
        <ButtonPrimary size="sm" onClick={() => router.push(ERoutePath.tokenExplorer)}>
          <FontAwesomeIcon icon={faCompass} />
          Explore Other Tokens
        </ButtonPrimary>
      }
      icon={<FontAwesomeIcon icon={faClockRotateLeft} width={32} />}
      text="Please try refreshing your page or revisiting this tab later. Meanwhile, hit the button below to explore other altcoins."
      variant="default"
    />
  );
};
