import { InferGetServerSidePropsType } from 'next';
import { API } from '@/api';
import { MarketCapPage } from '@/components/HomePageMarketCap/MarketCapPage';
import { Layout } from '@/components/layout/Layout';
import { apiServer } from '@/utils/api/apiServer';
import { ERoutePath } from '@/utils/ERoutePath';
import { logApp } from '@/utils/logApp';
import { getBaseURLByRequest } from '@/utils/nextjs/url';
import { CACHE_KEY, CACHE_TTL, redisCache } from '@/utils/redis/redisCache';
import { withL10n } from '@/utils/withL10n';

const log = logApp.create('/top-tokens-by-market-cap');
const fetchLatestMarketData = apiServer.web3Proxy('/web3/getLatestMarketData', 'GET');

const metaPageInfo = {
  title: 'Top Tokens by Market Cap | Crypto Market Overview with Moralis',
  description:
    'Explore the top tokens by market cap for a comprehensive market overview across multiple chains. Track the leading cryptocurrencies with ease.',
};
const HomePageMarketCap = ({
  marketCapCoinsData = [],
  fullUrl,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <Layout
      hasFooter={true}
      hasHeader={true}
      hasSearchBar={false}
      hasTwitterBanner={false}
      layout="tokenExp"
      metaDataTags={{
        title: metaPageInfo.title,
        description: metaPageInfo.description,
        url: `${fullUrl}/${ERoutePath.topTokensMarketCap}`,
      }}
      noPadding={true}
      screenWidth="full"
    >
      <MarketCapPage marketCapTokensList={marketCapCoinsData} />
    </Layout>
  );
};

export const getServerSideProps = withL10n.getServerSideProps(async (context) => {
  try {
    const cachedMarketCapData = await redisCache.read<API.Web3Proxy.Comps['GetLatestMarketDataResponseDto'][]>(
      CACHE_KEY.marketCap,
    );
    if (cachedMarketCapData) {
      return {
        props: {
          marketCapCoinsData: cachedMarketCapData,
          fullUrl: getBaseURLByRequest(context.req),
        },
      };
    }

    const [marketCapDataPage1, marketCapDataPage2] = await Promise.all([
      fetchLatestMarketData(context.req, {
        queryParams: { page: 1, perPage: 250 },
      }),
      fetchLatestMarketData(context.req, {
        queryParams: { page: 2, perPage: 250 },
      }),
    ]);

    const marketCapData = [...marketCapDataPage1, ...marketCapDataPage2];

    redisCache.saveQuiet({
      key: CACHE_KEY.marketCap,
      data: marketCapData,
      ttl: CACHE_TTL.THIRTY_MINUTES,
    });

    return {
      props: {
        marketCapCoinsData: marketCapData,
        fullUrl: getBaseURLByRequest(context.req),
      },
    };
  } catch (error) {
    log.warnError('[web3.GetLatestMarketData]', error, logApp.meta.bySSP(context, import.meta.url));
    throw error;
  }
});

export default HomePageMarketCap;
