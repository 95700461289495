import { Pagination } from 'moralis-ui';
import { HomePageTabs } from '@/components/common/HomePage/HomePageTabs';
import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import { GetLatestMarketDataResponseDto } from '@/services/models/GetLatestMarketDataResponseDto';
import { SortOrder } from '@/utils/sorting';
import { MarketCapPageUIEmpty } from './MarketCapPageUIEmpty';
import { MarketCapTableUI } from './MarketCapTableUI';
import { ValidColumnsMarketCap } from './ValidColumnsMarketCap';
import styles from './MarketCapPageUI.module.scss';

export const MarketCapPageUI = ({
  currentPage,
  itemsPerPage,
  totalPages,
  totalItems,
  currentPageItems,
  onChangePageNumber,
  tabId,
  onSortChange,
  sortingColumn,
  sortingOrder,
}: {
  currentPage: number;
  itemsPerPage: number;
  currentPageItems: GetLatestMarketDataResponseDto[];
  onChangePageNumber: (newPage: number) => void;
  tabId: EHomePageView;
  totalPages: number;
  totalItems: number;
  onSortChange: (column: ValidColumnsMarketCap) => void;
  sortingColumn: ValidColumnsMarketCap;
  sortingOrder: SortOrder;
}) => {
  return (
    <div className={styles.mainPage}>
      <div className={styles.header}>
        <HomePageTabs tabId={tabId} />
      </div>

      {currentPageItems.length > 0 ? (
        <div className={styles.pageInfoTable}>
          <div className={styles.tokensTable}>
            <MarketCapTableUI
              sortingColumn={sortingColumn}
              sortingOrder={sortingOrder}
              onSortChange={onSortChange}
              startIndex={(currentPage - 1) * itemsPerPage}
              items={currentPageItems}
            />
          </div>

          {totalPages > 1 && (
            <div className={styles.mainPagination}>
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                key={`pagination-${currentPage}`}
                totalItems={totalItems}
                onPageChange={onChangePageNumber}
              />
            </div>
          )}
        </div>
      ) : (
        <MarketCapPageUIEmpty />
      )}
    </div>
  );
};
