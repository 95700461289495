import { Container } from 'moralis-ui';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { HomeTokenAd } from './HomeTokenAd';
import styles from './HomeTokenAd.module.scss';

export const HomeTokenAdBanner = ({
  tokenAdsView,
  isLoading,
}: {
  tokenAdsView?: TokenAdsView | null;
  isLoading: boolean;
}) => {
  return (
    <Container className={styles.container}>
      <HomeTokenAd tokenAdsView={tokenAdsView} isLoading={isLoading} />
    </Container>
  );
};
