import { useMemo } from 'react';
import { useTableDataStateURL } from '@/helpers/hooks/useTableDataStateURL';
import { GetLatestMarketDataResponseDto } from '@/services/models/GetLatestMarketDataResponseDto';
import { slicePage } from '@/utils/pagination';
import { sortAsNumbers } from '@/utils/sorting';
import { fromQueryToMarketCap } from './ValidColumnsMarketCap';

const ITEMS_PER_PAGE = 20;
export const useMarketCap = (trendingTokenData: GetLatestMarketDataResponseDto[]) => {
  const { tableSort, tablePage, toggleSorting, updateCurrentPageIndex, resetQuery } = useTableDataStateURL({
    totalLength: trendingTokenData.length,
    fromQuery: fromQueryToMarketCap,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const sortedData = useMemo(
    () => sortAsNumbers([...trendingTokenData], (it) => it[tableSort.column], tableSort.order),
    [trendingTokenData, tableSort.column, tableSort.order],
  );

  return {
    currentPageItems: slicePage(tablePage.itemsPerPage, tablePage.currentIndex, sortedData),
    updateCurrentPageIndex,
    paginationData: tablePage,
    toggleSorting,
    tableSort,
    resetFilters: resetQuery,
  };
};
