import { useState } from 'react';
import useSWR from 'swr';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { fetchJSON } from '@/utils/api/fetchJSON';
import { logApp } from '@/utils/logApp';

const log = logApp.create('useAdsView');

const THIRTY_SECONDS = 30_000; // this is the same as 30 * 1000

type AdKeys = 'marketCap' | 'newCoins' | 'search' | 'sidebar' | 'tokenExplorer' | 'tokenSingleInfo' | 'trending';

export const useAdsView = (adKey: AdKeys) => {
  const [refreshCount, setRefreshCount] = useState(0);

  const { data, isLoading } = useSWR<TokenAdsView | null>(
    ['search-ads-view', adKey],
    async () => {
      try {
        const newData = await fetchJSON({
          url: '/api/search/ads-view',
          response: fetchJSON.as<TokenAdsView>,
        });

        if (newData.moneyId === data?.moneyId) {
          return newData;
        }

        setRefreshCount((prev) => prev + 1);
        return newData;
      } catch (err) {
        log.warnError('Failed to fetch ads view data', err);
        return null;
      }
    },
    {
      refreshInterval: refreshCount <= 3 ? THIRTY_SECONDS : 0,
    },
  );

  return {
    tokenAdsView: data,
    tokenAdsViewLoading: isLoading,
  };
};
