import clsx from 'clsx';
import { Table, TableCell } from 'moralis-ui';
import { TableSortIcon } from '@/components/common/TableSortIcon';
import { GetLatestMarketDataResponseDto } from '@/services/index';
import { SortOrder } from '@/utils/sorting';
import { MarketCapPageTableRowUI } from './MarketCapPageTableRowUI';
import { ValidColumnsMarketCap } from './ValidColumnsMarketCap';
import tableTokenStyle from './MarketCapPageTableRowUI.module.scss';
import styles from './MarketCapPageUI.module.scss';

export const MarketCapTableUI = ({
  sortingColumn,
  sortingOrder,
  onSortChange,
  startIndex,
  items,
}: {
  onSortChange: (column: ValidColumnsMarketCap) => void;
  sortingColumn: ValidColumnsMarketCap;
  sortingOrder: SortOrder;
  startIndex: number;
  items: GetLatestMarketDataResponseDto[];
}) => {
  const renderSortableHeader = (label: string, column: ValidColumnsMarketCap) => (
    <TableCell align="left" aria-label={column} variant="headerCell">
      <span
        className={clsx('caption-14-medium', styles.tokenTableHeader, styles.tokenTableHeaderFilters)}
        onClick={() => onSortChange(column)}
      >
        {label}
        <TableSortIcon isSelected={column === sortingColumn} isAsc={sortingOrder === SortOrder.ASC} />
      </span>
    </TableCell>
  );
  return (
    <Table
      transparent
      className={styles.mainPageTable}
      hasBorder={false}
      size="md"
      tbodyCells={items.map((token, index) => (
        <MarketCapPageTableRowUI
          key={`${token.address}-${token.name}-${token.symbol}`}
          token={token}
          rowNumber={startIndex + index + 1}
        />
      ))}
      theadCells={
        <tr>
          <TableCell align="left" aria-label="tokenInfo" className={tableTokenStyle.fieldToken} variant="headerCell">
            <span className={clsx('caption-14-medium', styles.tokenTableHeader)}># Token</span>
          </TableCell>
          {renderSortableHeader('Price', ValidColumnsMarketCap.currentPrice)}
          {renderSortableHeader('1H', ValidColumnsMarketCap.priceChangePercentage1h)}
          {renderSortableHeader('24H', ValidColumnsMarketCap.priceChangePercentage24h)}
          {renderSortableHeader('7D', ValidColumnsMarketCap.priceChangePercentage7d)}
          {renderSortableHeader('Volume (24H)', ValidColumnsMarketCap.totalVolume)}
          {renderSortableHeader('Market Cap', ValidColumnsMarketCap.marketCap)}
        </tr>
      }
    />
  );
};
