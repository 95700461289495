import { useRef } from 'react';

export const useScrollToElement = (offset: number = 0) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  const scrollToElement = () => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.getBoundingClientRect().top + window.pageYOffset + offset,
        behavior: 'smooth',
      });
    }
  };

  return {
    elementRef,
    scrollToElement,
  };
};
